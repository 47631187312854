import {calendar_ru_localization} from "@/data/calendar_ru_localization";

import("@/assets/scss/styles.scss")

import { createApp } from 'vue';
import {router} from "@/router";
import {store} from "@/store/store";
import App from '@/App.vue';

import PrimeVue from 'primevue/config';
import * as Sentry from "@sentry/vue";

const app =  createApp(App)
    .use(router)
    .use(store)
    app.use(PrimeVue, {
      locale: calendar_ru_localization
    });

app.config.errorHandler = (err) => {
  if (err && err.response && err.response.status === 429) {
    console.log('Превышено количество попыток. Попробуйте позже.');
  }
  console.error(err);
}

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/neyro-api\.adviz\.pro/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount('#app');
